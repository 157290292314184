/* eslint-disable security/detect-object-injection */
import dayjs from 'dayjs';
import { Location } from 'history';
import _, { get, isDate as isDateLodash, isEqual, isNil, isNumber } from 'lodash';
import { parse } from 'qs';
import shortid from 'shortid';
import { NavigateQuery } from 'src/containers/shared/types';
import { ErrorService, Toastify } from 'src/services';
import { isEmpty } from 'src/validations';
import { stringify } from './apiUtils';
import { handleRoundNumberValueTotal } from './formUtils';

export const emptyFunction = () => {};

export const getRandomId = (): string => shortid.generate();

export const generateArray = (length: number, initial = '') => Array(length).fill(initial);

export const getLocationState = (location: Location<string>) => {
  const locationState = location.state;
  const state = parse(locationState, { ignoreQueryPrefix: true });

  return state;
};

export const getWeekDay = (value: string) => {
  if (!value) return '';
  return dayjs(value).format('dddd');
};

export const getClassNameByStatus = (status) => {
  switch (status) {
    case 'Pending':
      return 'is-status-pending ';
    case 'Completed':
    case 'Approved':
    case 'Active':
      return 'is-status-active';
    case 'Rejected':
      return 'is-status-rejected';
    default:
      return '';
  }
};

export const getYesNoText = (value: boolean) => (value ? 'Yes' : 'No');

export const getNavigateUrl = (url: string) => (url.includes('http') ? url : `https://${url}`);

export const isURLImage = (url: string) => {
  if (isEmpty(url)) return false;

  const hasExtensionImage = [
    '.png',
    '.jpeg',
    '.jpg',
    'image/png',
    'image/jpeg',
    'image/jpg',
    'image/svg',
  ].some((ext) => url?.includes(ext));

  if (hasExtensionImage) {
    return true;
  }

  const state = parse(url?.split('?')[1], { ignoreQueryPrefix: false });
  const contentType = state?.['Content-Type'];
  const isImage = ['image/jpg', 'image/jpeg', 'image/png'].includes(contentType as string);

  return isImage;
};

export const handleGetError = (touched, errors, prefix) =>
  _.get(touched, prefix) ? _.get(errors, prefix) : '';

export const waiter = (time: number = 100) =>
  new Promise<Array<any>>((res) => setTimeout(() => res([]), time));

export const trimUrlHasEndDate = (url: string) => {
  const trimUrl = url.split('?')[0];
  const items = trimUrl.split('_');
  return items.slice(0, items.length - 1).join('');
};

export const trimUrl = (url: string) => {
  if (!url) return null;
  return url.split('?')[0];
};

export const handleClick = (callback) => (event: React.MouseEvent<any>) => {
  event.stopPropagation();
  event.preventDefault();
  if (callback) callback(event);
};

//link https://stackoverflow.com/questions/42674473/get-all-keys-of-a-deep-object-in-javascript
export const deepKeys = (t, path = []) => {
  const res =
    Object(t) === t
      ? Object.entries(t) // 1
          .flatMap(([k, v]) => deepKeys(v, [...path, k]))
      : [path.join('.')]; // 2
  return res?.filter((x) => !/\d$/.test(x));
};

export const scrollToTopError = (error: string[]) => {
  if (!isEmpty(error)) {
    const input = document?.querySelector(`[name='${error[0]}']`);
    input?.parentElement?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start',
    });
  }
};

export const handleShowErrorMsg = (error: Error, prefix: string = '') => {
  Toastify.error(`${!isEmpty(prefix) ? `${prefix}: ` : ''}${getErrorMessageFromResponse(error)}`);
};

export const getErrorMessageFromResponse = (error: Error) => {
  let errorMessage = ErrorService.MESSAGES.unknown;
  if (!isEmpty(error)) {
    if (typeof error?.message === 'string') {
      errorMessage = error?.message;
    } else {
      errorMessage = error?.message[0];
    }
  }

  return errorMessage;
};

export const handleScrollToTopError = <T>(errors: T) => {
  return setTimeout(() => {
    scrollToTopError(deepKeys(errors));
  }, 100);
};

export const getErrorMessage = (fieldName: string, { touched, errors }) => {
  if (!fieldName || !touched || !errors) return '';

  const error = get(errors, fieldName);

  return get(touched, fieldName) && error ? error : '';
};

export const isEqualPrevAndNextObjByPath = <T>({
  prevValues,
  nextValues,
  path,
  checkEqualLengthArray,
}: {
  prevValues: T;
  nextValues: T;
  path: string;
  checkEqualLengthArray?: boolean;
}) => {
  const prev = get(prevValues, path);
  const next = get(nextValues, path);
  return checkEqualLengthArray && Array.isArray(prev) && Array.isArray(next)
    ? prev.length === next.length
    : isEqual(prev, next);
};

export const getOptionsByEnum = (enumObject) => {
  if (isEmpty(enumObject)) return [];

  return Object.keys(enumObject).map((key) => ({
    label: enumObject[key],
    value: enumObject[key],
  }));
};

export const isString = (value: any): value is String => {
  return !!value && typeof value === 'string';
};

export const isDate = (value: any): value is Date => {
  return isDateLodash(value);
};

export const getOptionLabel = (options: { label: string; value: any }[], value) => {
  return options.find((option) => option.value === value)?.label || '';
};

export const getOptionMutiSelectLabel = (options: { label: string; value: string }[]) => {
  return options.map((item) => item.value);
};

export const convertNumberOrNull = (value: string | number, flag = true) => {
  if (!flag || isNil(value)) return null;

  if (typeof value === 'string' && !value) return null;

  return Number(value || 0);
};

export const convertNumberForAmountRmt = (value: string | number) => {
  if (value === null) return 0;
  return Number(value);
};

/**
 * Generic function to calculate totals in a list
 *
 * return the total amount
 */
export const calculateTotals = (itemList: any[], arrayOfFieldNames: string[]): number => {
  // if no item list don't do anything
  if (isEmpty(itemList)) {
    return 0;
  }
  // now calculate the subtotals/totals etc
  const listTotal = itemList.reduce((output, item) => {
    const itemLineTotal = calculateLineTotal(item, arrayOfFieldNames);
    const outputValue = isNaN(output) ? 0 : output;
    if (!isNumber(convertNumberOrNull(itemLineTotal))) return output;
    return handleRoundNumberValueTotal(outputValue) + handleRoundNumberValueTotal(itemLineTotal);
  }, 0);

  return Number(listTotal.toFixed(2));
};

export const calculateTotal = (itemList: { [key: string]: number }) => {
  // if no item list don't do anything
  if (isEmpty(itemList)) {
    return null;
  }

  const total = Object.entries(itemList).reduce((acc, [_, value]) => {
    if (!isNumber(value)) return acc;

    return acc + value;
  }, 0);

  return Number(total.toFixed(2));
};

/**
 * Calculate a total of an item, using the list of field names provided
 *
 * @param arrayOfFieldNames
 */
const calculateLineTotal = (itemListLine: Object, arrayOfFieldNames: string[]): number => {
  // if no item list don't do anything
  if (!itemListLine) {
    return null;
  }

  const totalAmount = arrayOfFieldNames.reduce((output, fieldName) => {
    const itemAmount = itemListLine[fieldName];

    if (!isNumber(convertNumberOrNull(itemAmount))) return output;

    return (Number(output) || 0) + (Number(itemAmount) || 0);
  }, 0);

  return Number(totalAmount);
};

/**
 * Taking a list of data and indexing each piece of data by some key. That way, the data is accessible by it's key in O(1) time
 * Note that a common performance mistake that developers make is to create a clone of the accumulator for each array iteration. i.e. return { ...accumulator, [val.id]: val };. This results in an O(n^2) algorithm.
 *
 * Example:
 * const groceries = [
 *   { id: 173, name: "Soup" },
 *   { id: 964, name: "Apple" },
 *   { id: 535, name: "Cheese" }
 * ];
 *
 * output:
 * {
 *   "173": { id: 173, name: "Soup" },
 *   "964": { id: 964, name: "Apple" },
 *   "535": { id: 535, name: "Cheese" },
 * }
 *
 */
export const getIndexedArrayByKey = (array: object[], key: string) => {
  return array.reduce((accumulator, value) => {
    accumulator[value[key]] = value;
    return accumulator;
  });
};

export const preparePrevPageNavigateQuery = ({
  prevPath,
  currentQuery,
}: {
  prevPath: string;
  currentQuery: string;
}): string => {
  const params = stringify({
    [NavigateQuery.PREVIOUS_PAGE]: prevPath,
    [NavigateQuery.SEARCH_TEXT]: currentQuery,
  });

  return params;
};

export const handleConvertToCurrency = (value: number) => {
  if (!value) return '$0.00';

  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

export const handleUpperCaseLoginName = (value: string) => {
  if (!value) return '';
  //ex: maile_brooks or maile brooks to Maile_Brooks
  const values = value.includes('_') ? value.split('_') : value.split(' ');
  const upperCaseValues = values.map((v) => (v ? v.charAt(0).toUpperCase() + v.slice(1) : ''));
  return upperCaseValues.join('_');
};

export const handleConvertProjectNumberToString = (
  value:
    | string
    | {
        number: string | number;
        [key: string]: any;
      }
): string => {
  if (!value) return '';

  if (isString(value)) {
    return value;
  }

  return value?.number?.toString() || '';
};
